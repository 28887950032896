<template>
  <div class="container py-5">
    <p class="fw-bold">第62屆廣州國際美博會</p>
    
    <p>時間：2023 09/04-06</p>
    <p>地點：廣州琶洲國際會展中心</p>
    <p>展位：12.2馆H12A/H12H</p>
    <p>
    第62屆廣州國際美博會，將於9月4日-9月6日在廣州琶洲國際會展中心開展。<br>
    美博會是美容行業最具影響力的展覽盛會之一，匯聚了來自全國乃至全球的美業專業人士。<br>
    OBSERV將與大家共同參與這場巔峰盛事
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_29.jpg"
    />

  </div>
</template>
<script>
export default {};
</script>
